import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FaBars } from 'react-icons/fa'
import { FaTimes } from 'react-icons/fa'

const Header = (props)=> (
  <header>
        <div className="header_nav sticky">
        <div className="container">
            <div className="site_branding">
                <h1 className="text_logo">
                <Link to='/'> 
                    Goh Yong Hua&nbsp;
                    <span className="logo_dot"></span>
                </Link>
                </h1>
            </div>
    
            <div className={`header_controls`} onClick={props.toggleMenu}>
              <div className="header_sliding_sidebar_control header_control_wrapper">
                <div className="sliding_sidebar_button">
                  <div className='header_control_icon'>
                    <div className={`menu_icon ${props.showMenuIcon}`}><FaBars /></div>
                    <div className={`menu_icon ${props.showCloseIcon}`}><FaTimes /></div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className={`main_menu ${props.showMenuClass}`} onClick={props.toggleMenu}>
              <ul id="top-menu" className="navbar">
                <li className="menu-item">
                  <Link to="/" activeStyle={{color:'#ff374a'}}>About</Link>
                </li>
                <li className="menu-item">
                  <Link to="/articles" activeStyle={{color:'#ff374a'}}>Articles</Link>
                </li>
                {/* <li className="menu-item">
                  <Link to="/" activeStyle={{color:'#ff374a'}}>About</Link>            
                </li> */}
              </ul>
                <span className="menu_mark_circle mark_circle_loaded">
                </span>
            </div>
            
        </div>
        </div>
      </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
