import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/baseLayout"
import SEO from "../components/seo"
import Listing from "../components/Listing/listing"

const CatPage = ({
  pageContext: { category },
  data: {
    posts: { edges, totalCount },
  }
}) => {
    // const { data } = prismicBasicPos
    
    // const date = data.release_date
    // const title = data.title.text
    // const category = data.category.document[0].data.cat_name.text
    
    return (
  <Layout>
    <SEO title={ `Category: ${category}` }/>
        <div className="container archive_header page-header">
            <h1 className="page-title section_title title">Category: { category }</h1>
        </div>
        <section className="container main_content_area blog_index_area flist_main_content_container">
            <h4 className="page-title screen-reader-text entry-title">Category: { category }</h4>
            <div className="row full_width_list">
                <div className="col12">
                {edges.map((edge)=>{
                    const node = edge.node
                    const key = node.uid
                    const title = node.data.title.text
                    const date = node.data.release_date
                    const content = node.data.body
                    return (
                      <Listing key={key} title={title} content={content} date={date} category={category} uid={key}></Listing>
                    )
                })}
                    {/* loop article here */}
                </div>

            </div>
        </section>
  </Layout>
)
}
export default CatPage

export const pageQuery = graphql`
query CatPage($category: String!){
  posts: allPrismicBasicPos(
    sort:{fields:[data___release_date], order: DESC}
    filter:{
      data:{
        category:{document:{elemMatch:{data:{cat_name:{text:{eq:$category}}}}}}
      }
    }) {
    totalCount
    edges {
      node {
        uid
        data{
          title{
            text
          }
          body{
            __typename
            ... on PrismicBasicPosBodyText{
              primary{
                text{
                  raw{
                    type
                    text
                  }
                }
              }
            }
          }
          release_date
          category{
            document{
              data{
                cat_name{
                  text
                }
              }
            }
          }
        }
      }
    }
  }
}
`