import React from 'react'

import { IoIosHeart } from 'react-icons/io'
import { FiCoffee } from 'react-icons/fi'

const Footer = () => (
    <footer className="site_footer container">
        <div className="copyright">
          made with <IoIosHeart className='footer__heart'/> and <FiCoffee className='footer__coffee' /> by Yong Hua &copy;{new Date().getFullYear()}
        </div>
    </footer>
)

export default Footer