import React from "react"
import "./styles/main.scss"

import Header from './header'
import Footer from './footer'


class Layout extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showMenu: false

    }
    this.toggleMenu = this.toggleMenu.bind(this)  
  }

  toggleMenu(){
    this.setState((state, props)=>(
      {showMenu: !state.showMenu}
    ))
  }
  // resetMenu(e, data){
  //   console.log(e.target.class)
  //   this.setState(()=>(
  //     {showMenu: false}
  //   ))
  // }

  render(){
    let showMenuClass = ""
    let showMenuIcon = ""
    let showCloseIcon = ""
    if (this.state.showMenu){
      showMenuClass = "showMenu" 
      showMenuIcon = "hideIcon" 
      showCloseIcon = "showIcon" 
    }
    else{
      showMenuClass = "hideMenu"
      showMenuIcon = "showIcon"
      showCloseIcon = "hideIcon"
    }
    return (
      <>
        <Header showMenuClass={showMenuClass}
                showMenuIcon={showMenuIcon}
                showCloseIcon={showCloseIcon}
                toggleMenu={this.toggleMenu}/>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main>{this.props.children}</main>
        </div>
        <Footer />
    </>
    )
  }
}
export default Layout