//listing component for category page
import React from "react"
import { kebabCase } from "lodash"
import { Link } from "gatsby"
import { FaChevronRight } from 'react-icons/fa'
// import { graphql } from "gatsby"
// import Layout from "./baseLayout"
// import SEO from "./seo"

const Listing = ({title, content, category, date, uid} )=>{
    let extract
    const formattedCat = kebabCase(category)
    for (let data of content){
        if (data.__typename==="PrismicBasicPosBodyText"){
            for (let datum of data.primary.text.raw) {
                if (datum.type==="paragraph"){
                    let text = datum.text.trim()
                    if (text > 140){
                        extract = text.substring(0, 141)
                        extract += "..."
                    }
                    else{
                        if (text.endsWith(".")){

                            extract = text + ".."
                        }
                        else{
                            extract = text + "..."
                        }
                    }
                    break
                }
            }
            break
        }
    }
    
    return(
        <article className="blog_post_container customhentry post type-post format-image hentry">
        <div className="post_body has_post_banner">
            <div className="post_header">
                <h2 className="entry-title title post_title">
                <Link to={`/articles/${uid}`} rel="bookmark">{ title }</Link>
                </h2>
            </div>
            <div className="post_meta_container clearfix">
                <div className="post_meta_info post_meta_row clearfix">
                    <span className="post_meta_item meta_item_author">
                    <span className="author vcard author_name">
                    <span className="fn">
                        <Link className="meta_author_name url" to="/">Goh Yong Hua</Link>
                    </span>
                    </span>
                    </span>
                    <span className="post_meta_item meta_item_category">
                        <Link to={`articles/categories/${formattedCat}`} rel="category tag">{category}</Link>
                    </span>
                    <span className="post_meta_item meta_item_date">
                    { date }
                    </span>
                </div>
            </div>
            <div className="post_info_wrapper">
                <div className="entry-summary blog_post_text blog_post_description">
                    <p>{ extract }</p>
                    <div className="blog_post_control_item blog_post_readmore">
                        <Link to={`/articles/${uid}`} className="more-link">Continue reading
                            <span className="continue_reading_dots">
                                <span className="continue_reading_squares">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <FaChevronRight className="readmore_icon"/>
                            </span>
                        </Link>

                    </div>
                </div>
            </div>
        </div> {/*end post body */}
        </article>
    )
}

export default Listing